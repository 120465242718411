import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Grid, Button, IconButton, Fab, useScrollTrigger, Zoom, FormControl, FormLabel, TextField, CircularProgress, Drawer, Chip, InputAdornment, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LocalizationProvider, DatePicker, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import GppGoodTwoToneIcon from '@mui/icons-material/GppGoodTwoTone';
import { useTranslation } from 'react-i18next';
import './i18n.js';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';

import cs from 'date-fns/locale/cs';
import de from 'date-fns/locale/de';
import sk from 'date-fns/locale/sk';
import en from 'date-fns/locale/en-GB';

const localeMap = {
    en: en,
    sk: sk,
    cs: cs,
    de: de,
  };


  const localeTextMap = {
    en: {
        components: {
            MuiPickersDay: {
                defaultProps: {
                    label: 'Select date',
                },
            },
            MuiButton: {
                defaultProps: {
                    cancelLabel: 'Cancel',
                    okLabel: 'OK',
                },
            },
        },
    },
    sk: {
        components: {
            MuiPickersDay: {
                defaultProps: {
                    label: 'Vyberte dátum',
                },
            },
            MuiButton: {
                defaultProps: {
                    cancelLabel: 'Zrušiť',
                    okLabel: 'OK',
                },
            },
        },
    },
    cs: {
        components: {
            MuiPickersDay: {
                defaultProps: {
                    label: 'Zvolte datum',
                },
            },
            MuiButton: {
                defaultProps: {
                    cancelLabel: 'Zrušit',
                    okLabel: 'OK',
                },
            },
        },
    },
    de: {
        components: {
            MuiPickersDay: {
                defaultProps: {
                    label: 'Datum auswählen',
                },
            },
            MuiButton: {
                defaultProps: {
                    cancelLabel: 'Abbrechen',
                    okLabel: 'OK',
                },
            },
        },
    },
};



  

const companyFreeDinghyMotor = [
    'Dream Yacht Charter',
    'Nautic Alliance',
    'Sailing club Vranjic',
    'Nova Eurospectra',
    'Istion Yachting',
    'Nautički centar Pina i Mare - NCP',
    'Vernicos Yachts',
    'Ban Tours',
    'WayPoint',
    'Under The Heavens',
    'Adriatic Sailing',
    'Noa Jedrenje',
    'Puresailing Yachting',
    'Orvas Yachting',
    'Sunturist Charter',
    'Bruneko Yacht Charter',
    'Sea and More Yachting',
    'Anemos Yachting',
    'Chilla Sailing',
    'Ocean Sailing House',
    'Isalos Yachting',
    'Smart Sail',
    'Ediba Sailing',
    'E-Sailing',
    'Vastardis Yachting',
    'Sailing Ionian Sea',
    'ALL-Seas',
    'Blue Sails',
    'ND Sails',
    'Sail Emerald',
    'Zen Yacht Charter',
    'Fancy Lobster',
    'Black&Soul',
    'Ionian Spirit',
    'Anyachting',
    'Mentis Yachting',
    'MGV Yachts Cruises',
    'Sail the Cyclades',
    'Anasa Experiences',
    'Anassa',
    'Serafina',
    'Chalkidiki Yachts',
    'Dream Charter Seychelles',
    'Niriides Cruises',
    'Latria-Charter',
    'Eazyblue',
    'Sail in Grrece',
    'Thalassa Sailing Travels'
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '70%',
    bgcolor: '#fff',
    border: '1px solid #000',
    boxShadow: 2,
    p: 4,
};

const newTheme = (theme) => createTheme({
    ...theme,
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    visibility: 'hidden',
                    maxHeight: 0,
                    maxWidth: 0,
                }
            }
        }
    }
});

const boatTypes = ["Sailing yacht", "Catamaran", "Power catamaran", "Gulet", "Motor boat", "Motor yacht"];
const sailTypes = ["classic/standard", "full batten", "furling/roll", "half batten"];
const yearOptions = ["< 2000", "> 2000", "> 2005", "> 2010", "> 2015", "> 2020", "> 2023", "All years"];
const berthsOptions = ["1+", "2+", "3+", "4+", "5+", "6+", "7+", "8+", "9+", "10+"];
const wcOptions = ["1+", "2+", "3+", "4+", "5+"];
const cabinsOptions = ["1+", "2+", "3+", "4+", "5+"];
const priceOptions = ["< 1000", "1000 - 2000", "2000 - 3000", "3000 - 4000", "4000 - 5000", "5000 +", "ALL"];
const lengthOptions = ["< 20", "20+", "25+", "30+", "35+", "40+", "45+", "50+", "55+", "60+"];

const Country = (props) => {
    const { t, i18n } = useTranslation();
    const currentLocale = i18n.language;
    useEffect(() => {
    const preferredLanguage = localStorage.getItem('preferredLanguage');
    if (preferredLanguage) {
        i18n.changeLanguage(preferredLanguage);
    }
}, []);

useEffect(() => {
    i18n.changeLanguage(currentLocale);
    localStorage.setItem('preferredLanguage', currentLocale);
}, [currentLocale]);
    const [boats, setBoats] = useState([]);
    const [allBoats, setAllBoats] = useState([]);
    const [filteredBoats, setFilteredBoats] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [query, setQuery] = useState('');
    const [brand, setBrand] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [loading, setLoading] = useState(false);
    const [cardsLoaded, setCardsLoaded] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedBoatType, setSelectedBoatType] = useState('');
    const [selectedSailTypes, setSelectedSailTypes] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [totalBoats, setTotalBoats] = useState(0);
    const [priceRange, setPriceRange] = useState(["ALL"]);
    const [selectedYear, setSelectedYear] = useState("All years");
    const [selectedBerths, setSelectedBerths] = useState(null);
    const [selectedWc, setSelectedWc] = useState(null);
    const [selectedCabins, setSelectedCabins] = useState(null);
    const [selectedLength, setSelectedLength] = useState(null);
    const { country } = useParams();

    const [imageUrls, setImageUrls] = useState({});

    function capitalizeFirstLetter(string) {
        return string
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const flagCountry = countries.find(c => c.title.trim() === capitalizeFirstLetter(country.trim()));
    const flagUrl = flagCountry ? `https://papasailor.com/w20/${flagCountry.code.toLowerCase()}.png` : null;

    const handleFavoriteClick = ({ boat_id, mainpicture, location, boatmodel, boatName, flag }) => {
        const favoriteData = {
            boat_id: boat_id,
            email: props.email,
            mainpicture: mainpicture,
            location: location,
            boatmodel: boatmodel,
            boatName: boatName,
            flag: flagUrl
        };

        axios.post('/favorites', favoriteData)
            .then(response => {
                toast.success(t('Successfully added to favorites!'));
            })
            .catch(error => {
                toast.error(t('Error adding to favorites. Are you logged in?'));
                console.error('Error adding to favorites:', error);
            });
    };

    useEffect(() => {
        const getNextSaturday = () => {
            const today = new Date();
            const nextSaturday = new Date();
            nextSaturday.setDate(today.getDate() + ((6 - today.getDay() + 7) % 7));
            return nextSaturday;
        };

        const defaultDate = getNextSaturday();
        setSelectedDate(defaultDate);
    }, []);

    useEffect(() => {
        const fetchBoats = async () => {
            setLoading(true);
            setCardsLoaded(false); // Nastavenie na false pred načítaním
            try {
                const response = await axios.post(`/countryElastic/${country}`, {
                    query: query.toLowerCase(),
                    boatType: selectedBoatType,
                    selectedDate: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : null,
                    sailTypes: selectedSailTypes,
                    brand: brand.toLowerCase(),
                });

                setAllBoats(response.data.boats);
                filterAndSetBoats(response.data.boats, 0);
                setCardsLoaded(true); // Nastavenie na true po načítaní a filtrovaní lodí
            } catch (error) {
                console.error('Error fetching boats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBoats();
    }, [query, selectedDate, selectedSailTypes, selectedYear, brand, selectedBoatType]);


    useEffect(() => {
        filterAndSetBoats(allBoats, page);
    }, [priceRange, selectedBoatType, selectedBerths, selectedWc, selectedCabins, selectedYear, selectedLength]);

    const filterAndSetBoats = (boats, page) => {
        let filteredBoats = boats;

        if (selectedSailTypes.length > 0) {
            filteredBoats = filteredBoats.filter(boat => selectedSailTypes.includes(boat.sail));
        }

        if (selectedYear !== "All years") {
            if (selectedYear === "< 2000") {
                filteredBoats = filteredBoats.filter(boat => boat.buildYear < 2000);
            } else {
                const year = parseInt(selectedYear.split(" ")[1]);
                filteredBoats = filteredBoats.filter(boat => boat.buildYear >= year);
            }
        }

        if (selectedBoatType) {
            filteredBoats = filteredBoats.filter(boat => boat.typeOfBoat === selectedBoatType);
        }

        if (selectedBerths) {
            const berths = parseInt(selectedBerths);
            filteredBoats = filteredBoats.filter(boat => boat.berths >= berths);
        }

        if (selectedWc) {
            const wc = parseInt(selectedWc);
            filteredBoats = filteredBoats.filter(boat => boat.wc >= wc);
        }

        if (selectedCabins) {
            const cabins = parseInt(selectedCabins);
            filteredBoats = filteredBoats.filter(boat => boat.cabins >= cabins);
        }

        if (selectedLength) {
            filteredBoats = filteredBoats.filter(boat => {
                if (selectedLength.includes("<")) {
                    const value = parseInt(selectedLength.split(" ")[1]);
                    return boat.virtual_lenght <= value;
                } else {
                    const value = parseInt(selectedLength.split("+")[0]);
                    return boat.virtual_lenght >= value;
                }
            });
        }

        if (priceRange.length > 0 && !priceRange.includes("ALL")) {
            filteredBoats = filteredBoats.filter(boat => {
                return priceRange.some(range => {
                    if (range.includes("<")) {
                        const value = parseInt(range.split(" ")[1]);
                        return boat.price < value;
                    } else if (range.includes("+")) {
                        const value = parseInt(range.split(" ")[0]);
                        return boat.price >= value;
                    } else {
                        const [min, max] = range.split(" - ").map(Number);
                        return boat.price >= min && boat.price <= max;
                    }
                });
            });
        }

        if (companyName) {
            filteredBoats = filteredBoats.filter(boat => boat.company_name.toLowerCase().includes(companyName.toLowerCase()));
        }

        filteredBoats = filteredBoats.sort((a, b) => a.price - b.price);

        setFilteredBoats(filteredBoats);
        setTotalBoats(filteredBoats.length);
        setBoats(filteredBoats.slice(0, (page + 1) * pageSize));
        setHasMore(filteredBoats.length > (page + 1) * pageSize);
    };

    useEffect(() => {
        const loadImages = async () => {
            const urls = {};

            for (const boat of boats) {
                const boatUrls = await Promise.all(boat.pictures_all.map(async (picture) => {
                    return await getPictureUrl(picture);
                }));
                urls[boat.boatId] = boatUrls;
            }

            setImageUrls(urls);
        };

        if (boats.length > 0) {
            loadImages();
        }
    }, [boats]);




    const checkImageExists = async (url) => {
        try {
            const response = await axios.head(url);
            return response.status === 200;
        } catch (error) {
            return false;
        }
    };

    const getPictureUrl = (url) => {
        const parts = url.split('/');
        const boatId = parts[parts.length - 3];
        const imageName = parts[parts.length - 1];
        //const cacheUrl = `https://cache.wzd.sk/images/${boatId}/${imageName}`;
        const cacheUrl = url;

        console.log(`Using cache image: ${cacheUrl}`);
        return cacheUrl;
    };




    const loadMore = () => {
        setPage((prevPage) => {
            const nextPage = prevPage + 1;
            setBoats((prevBoats) => [...prevBoats, ...filteredBoats.slice(prevBoats.length, (nextPage + 1) * pageSize)]);
            return nextPage;
        });
    };

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
        setPage(0);
        setBoats([]);
        setAllBoats([]);
    };

    const handleQueryClear = () => {
        setQuery('');
        setPage(0);
        setBoats([]);
        setAllBoats([]);
    };

    const handleBrandChange = (e) => {
        setBrand(e.target.value);
        setPage(0);
        setBoats([]);
        setAllBoats([]);
    };

    const handleBrandClear = () => {
        setBrand('');
        setPage(0);
        setBoats([]);
        setAllBoats([]);
    };

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handleCompanyNameClear = () => {
        setCompanyName('');
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleBoatTypeChange = (type) => {
        setSelectedBoatType(type);
        setSelectedSailTypes([]);
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handleSailTypeChange = (type) => {
        setSelectedSailTypes((prev) =>
            prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
        );
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handlePriceRangeChange = (value) => {
        setPriceRange((prev) => {
            if (value === "ALL") {
                return ["ALL"];
            } else {
                const newRange = prev.includes(value)
                    ? prev.filter(r => r !== value)
                    : [...prev.filter(r => r !== "ALL"), value];
                return newRange.length === 0 ? ["ALL"] : newRange;
            }
        });
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handleYearChange = (year) => {
        setSelectedYear(year);
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handleBerthsChange = (berths) => {
        setSelectedBerths(berths);
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handleWcChange = (wc) => {
        setSelectedWc(wc);
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handleCabinsChange = (cabins) => {
        setSelectedCabins(cabins);
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const handleLengthChange = (length) => {
        setSelectedLength(length);
        setPage(0);
        filterAndSetBoats(allBoats, 0);
    };

    const getNextSaturday = () => {
        const today = new Date();
        const nextSaturday = new Date();
        nextSaturday.setDate(today.getDate() + ((6 - today.getDay() + 7) % 7));
        return nextSaturday;
    };

    const defaultDate = getNextSaturday();

    const resetFilters = () => {
        setQuery('');
        setBrand('');
        setCompanyName('');
        setSelectedBoatType('Sailing yacht');
        setSelectedSailTypes([]);
        setSelectedDate(defaultDate);
        setPriceRange(["ALL"]);
        setSelectedYear("All years");
        setSelectedBerths(null);
        setSelectedWc(null);
        setSelectedCabins(null);
        setSelectedLength(null);
        setPage(0);
        setBoats([]);
        setAllBoats([]);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleScrollTopClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setPage(0);
        setBoats([]);
        setAllBoats([]);
        setDatePickerOpen(false);
    };

    const handleDatePickerOpen = () => {
        setDatePickerOpen(true);
    };

    const renderArrowPrev = (onClickHandler, hasPrev, label) => {
        return hasPrev && (
            <IconButton onClick={onClickHandler} style={{ position: 'absolute', top: '50%', left: 0, zIndex: 2, color: '#fff', backgroundColor: '#000', opacity: 0.7, borderRadius: '50%' }}>
                <ArrowBackIosIcon fontSize="large" />
            </IconButton>
        );
    };

    const renderArrowNext = (onClickHandler, hasNext, label) => {
        return hasNext && (
            <IconButton onClick={onClickHandler} style={{ position: 'absolute', top: '50%', right: 0, zIndex: 2, color: '#fff', backgroundColor: '#000', opacity: 0.7, borderRadius: '50%' }}>
                <ArrowForwardIosIcon fontSize="large" />
            </IconButton>
        );
    };

    const formatDateRange = (startDate) => {
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 7);
        return `${format(startDate, 'dd.MM.yyyy')} - ${format(endDate, 'dd.MM.yyyy')}`;
    };

    const handleDetailsClick = (boatId, startDate) => {
        const startDateUnix = Math.floor(new Date(startDate).getTime() / 10000);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 7);
        const endDateUnix = Math.floor(new Date(endDate).getTime() / 10000);
        window.open(`/details/${boatId}/${startDateUnix + '0000'}/${endDateUnix + '0000'}`, '_blank');
    };

    return (
        <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[currentLocale]}>
                <Box id="back-to-top-anchor" sx={{ padding: 2 }}>
                    <Typography sx={{ color: "#484848" }}>
                        <Box sx={{ display: 'flex', gap: 3, marginBottom: 4, marginTop: 4 }}>
                            <Typography variant="h4">
                                <img src={flagUrl} width="38" />
                                {t(capitalizeFirstLetter(country))}
                            </Typography>
                        </Box>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}>
                        <Button
                            variant="outlined"
                            startIcon={<FilterListIcon />}
                            onClick={toggleDrawer}
                        >
                            Filter ({totalBoats})
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleDatePickerOpen}
                            style={{ marginLeft: '8px' }}
                        >
                            {selectedDate ? formatDateRange(selectedDate) : 'Dátum od'}
                        </Button>
                        {datePickerOpen && (
                            <ThemeProvider theme={newTheme}>
           
                                    <DatePicker
                                        value={selectedDate}
                                        localeText={{ cancelButtonLabel: t('cancel'), okButtonLabel: t('ok'), datePickerToolbarTitle: t('Choose date') }}
                               
                                        disablePast="true"
                                        onChange={handleDateChange}
                                        shouldDisableDate={(date) => date.getDay() !== 6}
                                        renderDay={(day, _value, DayComponentProps) => (
                                            <PickersDay {...DayComponentProps} />
                                        )}
                                        onClose={() => setDatePickerOpen(false)}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box sx={{ display: 'none' }}>
                                                <input ref={inputRef} {...inputProps} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                        open
                                    />
                   
                            </ThemeProvider>
 
                        )}


                    </Box>
                    {loading || !cardsLoaded ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>

                            <CircularProgress />
                            <SailingTwoToneIcon
                                color="info"
                                style={{
                                    position: 'absolute',
                                    fontSize: 20,
                                }}
                            />

                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {boats.map((boat) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={boat.boatId}>
                                    <Card sx={{ borderRadius: '6px', overflow: 'hidden', position: 'relative', boxShadow: 'none', border: '1px solid #e0e0e0' }}>
                                        <Carousel
                                            showThumbs={false}
                                            showIndicators={false}
                                            renderArrowPrev={renderArrowPrev}
                                            renderArrowNext={renderArrowNext}
                                            swipeable={false}
                                        >
                                            {(imageUrls[boat.boatId] || boat.pictures_all).map((picture, index) => (
                                                <div key={index} style={{ position: 'relative' }}>
                                                    <LazyLoadImage
                                                        src={picture}
                                                        alt={`Boat picture ${index}`}
                                                        effect="blur"
                                                        style={{ objectFit: 'cover', width: '100%', height: '300px' }}
                                                    />
                                                    {companyFreeDinghyMotor.includes(boat.company_name) && (
                                                        <Badge
                                                            color="info"
                                                            badgeContent={t("free")}
                                                            max={9999999}
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            sx={{ position: 'absolute', top: 15, left: 8 }}
                                                        >
                                                            <Chip
                                                                label={t("Dinghy motor")}
                                                                color="primary"
                                                                style={{ marginLeft: 0 }}
                                                                icon={<GppGoodTwoToneIcon />}
                                                            />
                                                        </Badge>
                                                    )}
                                                </div>
                                            ))}
                                        </Carousel>

                                        <CardContent sx={{ textAlign: 'left', paddingBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <img src={flagUrl} alt={country} />
                                                        <Typography variant="h6" component="div">
                                                            {boat.yachtModelName + " | " + boat.boatName}
                                                        </Typography>
                                                        <IconButton onClick={() => handleFavoriteClick({
                                                            boat_id: boat.boatId,
                                                            mainpicture: boat.boatPicture,
                                                            location: boat.location,
                                                            boatmodel: boat.yachtModelName,
                                                            boatName: boat.boatName,
                                                            flag: "flag"
                                                        })}>
                                                            <FavoriteBorderIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>{boat.buildYear} </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {boat.location}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: 10, marginBottom: 2, color: "#2e79a0" }}>
                                                    {boat.company_name}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, color: 'gray', marginLeft: 0 }}>
                                                    Deposit € {boat.deposit}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: 14, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                                    <HailOutlinedIcon sx={{ fontSize: 14, marginRight: 0.2 }} />
                                                    {boat.berths}
                                                    <WcOutlinedIcon sx={{ fontSize: 14, marginLeft: 2, marginRight: 0.2 }} />
                                                    {boat.wc}
                                                    <MeetingRoomOutlinedIcon sx={{ fontSize: 14, marginLeft: 2, marginRight: 0.2 }} />
                                                    {boat.cabins}
                                                    <SailingOutlinedIcon sx={{ fontSize: 14, marginLeft: 2, marginRight: 0.2 }} />
                                                    {t(boat.sail)}
                                                </Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                                    <Typography variant="body2" color="primary" sx={{ fontSize: 17 }}>
                                                        € {boat.price}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: 14, textDecoration: 'line-through', color: 'gray', marginLeft: 1 }}>
                                                        € {boat.list_price}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                        <Box sx={{ textAlign: 'center', paddingBottom: '16px' }}>
                                            <Button variant="outlined" color="primary" onClick={() => handleDetailsClick(boat.boatId, selectedDate)} sx={{ width: "95%", marginBottom: -1 }}>
                                                Detaily
                                            </Button>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {!loading && hasMore && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                            <Button variant="contained" color="primary" onClick={loadMore}>
                                {t("Load More Boats")}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                >
                    <Box sx={{ width: 250, padding: 2 }}>
                        <TextField
                            label={t("Place, for ex. Trogir")}
                            variant="outlined"
                            fullWidth
                            value={query}
                            onChange={handleQueryChange}
                            sx={{ marginBottom: 2 }}
                            InputProps={{
                                endAdornment: query && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleQueryClear}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            label={t("Boat brand for ex. Bavaria")}
                            variant="outlined"
                            fullWidth
                            value={brand}
                            onChange={handleBrandChange}
                            sx={{ marginBottom: 2 }}
                            InputProps={{
                                endAdornment: brand && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleBrandClear}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
                            <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: 600 }}>{t("Type of boat")}</FormLabel>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {boatTypes.map((type) => (
                                    <Chip
                                        key={type}
                                        label={t(type)}
                                        onClick={() => handleBoatTypeChange(type)}
                                        color={selectedBoatType === type ? "success" : "default"}
                                        variant={selectedBoatType === type ? "filled" : "outlined"}
                                    />
                                ))}
                            </Box>
                        </FormControl>
                        <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
                            <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: 600 }}>{t("Price")}</FormLabel>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {priceOptions.map((price) => (
                                    <Chip
                                        key={price}
                                        label={price}
                                        onClick={() => handlePriceRangeChange(price)}
                                        color={priceRange.includes(price) ? "primary" : "default"}
                                        variant={priceRange.includes(price) ? "filled" : "outlined"}
                                    />
                                ))}
                            </Box>
                        </FormControl>
                        <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
                            <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: 600 }}>{t("Length")}</FormLabel>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {lengthOptions.map((length) => (
                                    <Chip
                                        key={length}
                                        label={length}
                                        onClick={() => handleLengthChange(length)}
                                        color={selectedLength === length ? "primary" : "default"}
                                        variant={selectedLength === length ? "filled" : "outlined"}
                                    />
                                ))}
                            </Box>
                        </FormControl>
                        <Accordion sx={{ marginBottom: 2, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{t("Sails type")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {sailTypes.map((type) => (
                                        <Chip
                                            key={type}
                                            label={t(type)}
                                            onClick={() => handleSailTypeChange(type)}
                                            color={selectedSailTypes.includes(type) ? "secondary" : "default"}
                                            variant={selectedSailTypes.includes(type) ? "filled" : "outlined"}
                                            deleteIcon={selectedSailTypes.includes(type) ? <CloseIcon /> : undefined}
                                            onDelete={selectedSailTypes.includes(type) ? () => handleSailTypeChange(type) : undefined}
                                        />
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ marginBottom: 2, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{t("Build year")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {yearOptions.map((year) => (
                                        <Chip
                                            key={year}
                                            label={year}
                                            onClick={() => handleYearChange(year)}
                                            color={selectedYear === year ? "success" : "default"}
                                            variant={selectedYear === year ? "filled" : "outlined"}
                                        />
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ marginBottom: 2, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{t("Persons")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {berthsOptions.map((berth) => (
                                        <Chip
                                            key={berth}
                                            label={berth}
                                            onClick={() => handleBerthsChange(berth)}
                                            color={selectedBerths === berth ? "success" : "default"}
                                            variant={selectedBerths === berth ? "filled" : "outlined"}
                                        />
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ marginBottom: 2, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{t("Heads")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {wcOptions.map((wc) => (
                                        <Chip
                                            key={wc}
                                            label={wc}
                                            onClick={() => handleWcChange(wc)}
                                            color={selectedWc === wc ? "success" : "default"}
                                            variant={selectedWc === wc ? "filled" : "outlined"}
                                        />
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ marginBottom: 2, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{t("Cabins")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {cabinsOptions.map((cabin) => (
                                        <Chip
                                            key={cabin}
                                            label={cabin}
                                            onClick={() => handleCabinsChange(cabin)}
                                            color={selectedCabins === cabin ? "success" : "default"}
                                            variant={selectedCabins === cabin ? "filled" : "outlined"}
                                        />
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ marginBottom: 2, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{t("Charter company")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label={t("Charter company")}
                                    variant="outlined"
                                    fullWidth
                                    value={companyName}
                                    onChange={handleCompanyNameChange}
                                    sx={{ marginBottom: 2 }}
                                    InputProps={{
                                        endAdornment: companyName && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleCompanyNameClear}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                            <Button variant="outlined" color="secondary" onClick={resetFilters}>
                                {t("Reset Filters")}
                            </Button>
                        </Box>
                    </Box>
                </Drawer>
            </LocalizationProvider>
            <Zoom in={trigger}>
                <Box onClick={handleScrollTopClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                    <Fab color="primary" size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon />
                    </Fab>
                </Box>
            </Zoom>
            <div></div>
        </React.Fragment>
    );
};

export default Country;




const countries = [
    { code: 'AF', title: 'Afghanistan', value: 100184 },
    { code: 'AL', title: 'Albania ', value: 100125 },
    { code: 'DZ', title: 'Algeria ', value: 100111 },
    { code: 'AS', title: 'American Samoa', value: 1221674 },
    { code: 'AD', title: 'Andorra ', value: 100137 },
    { code: 'AO', title: 'Angola', value: 1221676 },
    { code: 'AI', title: 'Anguilla', value: 103651 },
    { code: 'AQ', title: 'Antarctica', value: 1221680 },
    { code: 'AG', title: 'Antigua and Barbuda', value: 1221684 },
    { code: 'AR', title: 'Argentina', value: 100163 },
    { code: 'AM', title: 'Armenia', value: 100135 },
    { code: 'AW', title: 'Aruba', value: 1221693 },
    { code: 'AU', title: 'Australia', value: 100101 },
    { code: 'AT', title: 'Austria', value: 100102 },
    { code: 'AZ', title: 'Azerbaijan', value: 100205 },
    { code: 'BS', title: 'Bahamas', value: 491658 },
    { code: 'BH', title: 'Bahrain', value: 1221686 },
    { code: 'BD', title: 'Bangladesh', value: 100207 },
    { code: 'BB', title: 'Barbados', value: 1221705 },
    { code: 'BY', title: 'Belarus', value: 100131 },
    { code: 'BE', title: 'Belgium', value: 100106 },
    { code: 'BZ', title: 'Belize', value: 100218 },
    { code: 'BJ', title: 'Benin', value: 100221 },
    { code: 'BM', title: 'Bermuda', value: 100213 },
    { code: 'BT', title: 'Bhutan', value: 100225 },
    { code: 'BO', title: 'Bolivia', value: 100226 },
    { code: 'BA', title: 'Bosnia and Herzegovina', value: 100124 },
    { code: 'BW', title: 'Botswana', value: 100228 },
    { code: 'BR', title: 'Brazil', value: 100107 },
    { code: 'IO', title: 'British Indian Ocean Territory', value: 491656 },
    { code: 'VG', title: 'British Virgin Islands', value: 1221716 },
    { code: 'BN', title: 'Brunei', value: 100229 },
    { code: 'BG', title: 'Bulgaria', value: 100108 },
    { code: 'BF', title: 'Burkina Faso', value: 100231 },
    { code: 'BI', title: 'Burundi', value: 100233 },
    { code: 'KH', title: 'Cambodia', value: 100242 },
    { code: 'CM', title: 'Cameroon', value: 100239 },
    { code: 'CA', title: 'Canada', value: 100109 },
    { code: 'CV', title: 'Cape Verde', value: 100243 },
    { code: 'KY', title: 'Cayman Islands', value: 100216 },
    { code: 'CF', title: 'Central African Republic', value: 100244 },
    { code: 'TD', title: 'Chad', value: 100245 },
    { code: 'CL', title: 'Chile', value: 1001092 },
    { code: 'CN', title: 'China', value: 100110 },
    { code: 'CX', title: 'Christmas Island', value: 206671 },
    { code: 'CC', title: 'Cocos [Keeling] Islands', value: 206672 },
    { code: 'CO', title: 'Colombia', value: 100250 },
    { code: 'KM', title: 'Comoros', value: 100251 },
    { code: 'CK', title: 'Cook Islands', value: 100249 },
    { code: 'CR', title: 'Costa Rica', value: 100254 },
    { code: 'HR', title: 'Croatia', value: 1 },
    { code: 'CU', title: 'Cuba', value: 100255 },
    { code: 'CW', title: 'Curaçao', value: 168301 },
    { code: 'CY', title: 'Cyprus', value: 100126 },
    { code: 'CZ', title: 'Czech Republic', value: 100112 },
    { code: 'CD', title: 'Democratic Republic of the Congo', value: 100257 },
    { code: 'DK', title: 'Denmark', value: 100113 },
    { code: 'DJ', title: 'Djibouti', value: 100258 },
    { code: 'DM', title: 'Dominica', value: 100262 },
    { code: 'DO', title: 'Dominican Republic', value: 100263 },
    { code: 'EC', title: 'Ecuador', value: 100267 },
    { code: 'EG', title: 'Egypt', value: 100114 },
    { code: 'SV', title: 'El Salvador', value: 100274 },
    { code: 'GQ', title: 'Equatorial Guinea', value: 100268 },
    { code: 'ER', title: 'Eritrea', value: 100270 },
    { code: 'EE', title: 'Estonia', value: 100115 },
    { code: 'ET', title: 'Ethiopia', value: 100271 },
    { code: 'FK', title: 'Falkland Islands', value: 100277 },
    { code: 'FO', title: 'Faroe Islands', value: 100118 },
    { code: 'FJ', title: 'Fiji', value: 100276 },
    { code: 'FI', title: 'Finland', value: 100119 },
    { code: 'FR', title: 'France', value: 100120 },
    { code: 'GF', title: 'French Guiana', value: 100280 },
    { code: 'PF', title: 'French Polynesia', value: 100278 },
    { code: 'GA', title: 'Gabon', value: 100282 },
    { code: 'GM', title: 'Gambia', value: 100286 },
    { code: 'GE', title: 'Georgia', value: 100128 },
    { code: 'DE', title: 'Germany', value: 100122 },
    { code: 'GH', title: 'Ghana', value: 100287 },
    { code: 'GI', title: 'Gibraltar', value: 100288 },
    { code: 'GR', title: 'Greece', value: 100123 },
    { code: 'GL', title: 'Greenland', value: 100124 },
    { code: 'GD', title: 'Grenada', value: 100292 },
    { code: 'GP', title: 'Guadeloupe', value: 100293 },
    { code: 'GU', title: 'Guam', value: 100294 },
    { code: 'GT', title: 'Guatemala', value: 100298 },
    { code: 'GG', title: 'Guernsey', value: 100296 },
    { code: 'GN', title: 'Guinea', value: 100299 },
    { code: 'GW', title: 'Guinea-Bissau', value: 100301 },
    { code: 'GY', title: 'Guyana', value: 100303 },
    { code: 'HT', title: 'Haiti', value: 100304 },
    { code: 'HN', title: 'Honduras', value: 100307 },
    { code: 'HK', title: 'Hong Kong', value: 100125 },
    { code: 'HU', title: 'Hungary', value: 100126 },
    { code: 'IS', title: 'Iceland', value: 100129 },
    { code: 'IN', title: 'India', value: 100130 },
    { code: 'ID', title: 'Indonesia', value: 100131 },
    { code: 'IR', title: 'Iran', value: 100132 },
    { code: 'IQ', title: 'Iraq', value: 100133 },
    { code: 'IE', title: 'Ireland', value: 100134 },
    { code: 'IM', title: 'Isle of Man', value: 100135 },
    { code: 'IL', title: 'Israel', value: 100136 },
    { code: 'IT', title: 'Italy', value: 100147 },
    { code: 'JM', title: 'Jamaica', value: 100138 },
    { code: 'JP', title: 'Japan', value: 100139 },
    { code: 'JE', title: 'Jersey', value: 100140 },
    { code: 'JO', title: 'Jordan', value: 100141 },
    { code: 'KZ', title: 'Kazakhstan', value: 100142 },
    { code: 'KE', title: 'Kenya', value: 100143 },
    { code: 'KI', title: 'Kiribati', value: 100144 },
    { code: 'KW', title: 'Kuwait', value: 100145 },
    { code: 'KG', title: 'Kyrgyzstan', value: 100146 },
    { code: 'LA', title: 'Lao Peoples Democratic Republic', value: 100147 },
    { code: 'LV', title: 'Latvia', value: 100148 },
    { code: 'LB', title: 'Lebanon', value: 100149 },
    { code: 'LS', title: 'Lesotho', value: 100150 },
    { code: 'LR', title: 'Liberia', value: 100151 },
    { code: 'LY', title: 'Libya', value: 100152 },
    { code: 'LI', title: 'Liechtenstein', value: 100153 },
    { code: 'LT', title: 'Lithuania', value: 100154 },
    { code: 'LU', title: 'Luxembourg', value: 100155 },
    { code: 'MO', title: 'Macao', value: 100156 },
    { code: 'MG', title: 'Madagascar', value: 100157 },
    { code: 'MW', title: 'Malawi', value: 100158 },
    { code: 'MY', title: 'Malaysia', value: 100159 },
    { code: 'MV', title: 'Maldives', value: 100160 },
    { code: 'ML', title: 'Mali', value: 100161 },
    { code: 'MT', title: 'Malta', value: 100162 },
    { code: 'MH', title: 'Marshall Islands', value: 100163 },
    { code: 'MQ', title: 'Martinique', value: 100164 },
    { code: 'MR', title: 'Mauritania', value: 100165 },
    { code: 'MU', title: 'Mauritius', value: 100166 },
    { code: 'YT', title: 'Mayotte', value: 100167 },
    { code: 'MX', title: 'Mexico', value: 100168 },
    { code: 'FM', title: 'Micronesia', value: 100169 },
    { code: 'MD', title: 'Moldova', value: 100170 },
    { code: 'MC', title: 'Monaco', value: 100171 },
    { code: 'MN', title: 'Mongolia', value: 100172 },
    { code: 'ME', title: 'Montenegro', value: 100173 },
    { code: 'MS', title: 'Montserrat', value: 1221920 },
    { code: 'MA', title: 'Morocco', value: 100175 },
    { code: 'MZ', title: 'Mozambique', value: 100176 },
    { code: 'MM', title: 'Myanmar', value: 100177 },
    { code: 'NA', title: 'Namibia', value: 100178 },
    { code: 'NR', title: 'Nauru', value: 100179 },
    { code: 'NP', title: 'Nepal', value: 100180 },
    { code: 'NL', title: 'Netherlands', value: 100181 },
    { code: 'NC', title: 'New Caledonia', value: 100182 },
    { code: 'NZ', title: 'New Zealand', value: 100183 },
    { code: 'NI', title: 'Nicaragua', value: 100184 },
    { code: 'NE', title: 'Niger', value: 100185 },
    { code: 'NG', title: 'Nigeria', value: 100186 },
    { code: 'NU', title: 'Niue', value: 100187 },
    { code: 'NF', title: 'Norfolk Island', value: 100188 },
    { code: 'KP', title: 'North Korea', value: 100189 },
    { code: 'MK', title: 'North Macedonia', value: 100190 },
    { code: 'NO', title: 'Norway', value: 100191 },
    { code: 'OM', title: 'Oman', value: 100192 },
    { code: 'PK', title: 'Pakistan', value: 100193 },
    { code: 'PW', title: 'Palau', value: 100194 },
    { code: 'PS', title: 'Palestine', value: 100195 },
    { code: 'PA', title: 'Panama', value: 100196 },
    { code: 'PG', title: 'Papua New Guinea', value: 100197 },
    { code: 'PY', title: 'Paraguay', value: 100198 },
    { code: 'PE', title: 'Peru', value: 100199 },
    { code: 'PH', title: 'Philippines', value: 100200 },
    { code: 'PN', title: 'Pitcairn Islands', value: 100201 },
    { code: 'PL', title: 'Poland', value: 100202 },
    { code: 'PT', title: 'Portugal', value: 100203 },
    { code: 'QA', title: 'Qatar', value: 100204 },
    { code: 'RO', title: 'Romania', value: 100205 },
    { code: 'RU', title: 'Russia', value: 100206 },
    { code: 'RW', title: 'Rwanda', value: 100207 },
    { code: 'RE', title: 'Réunion', value: 100208 },
    { code: 'BL', title: 'Saint Barthélemy', value: 100209 },
    { code: 'SH', title: 'Saint Helena, Ascension and Tristan da Cunha', value: 100210 },
    { code: 'KN', title: 'Saint Kitts and Nevis', value: 100211 },
    { code: 'LC', title: 'Saint Lucia', value: 100212 },
    { code: 'MF', title: 'Saint Martin', value: 100213 },
    { code: 'PM', title: 'Saint Pierre and Miquelon', value: 100214 },
    { code: 'VC', title: 'Saint Vincent and the Grenadines', value: 100215 },
    { code: 'WS', title: 'Samoa', value: 100216 },
    { code: 'SM', title: 'San Marino', value: 100217 },
    { code: 'ST', title: 'São Tomé and Príncipe', value: 100218 },
    { code: 'SA', title: 'Saudi Arabia', value: 100219 },
    { code: 'SN', title: 'Senegal', value: 100220 },
    { code: 'RS', title: 'Serbia', value: 100221 },
    { code: 'SC', title: 'Seychelles', value: 100222 },
    { code: 'SL', title: 'Sierra Leone', value: 100223 },
    { code: 'SG', title: 'Singapore', value: 100224 },
    { code: 'SX', title: 'Sint Maarten', value: 100225 },
    { code: 'SK', title: 'Slovakia', value: 100226 },
    { code: 'SI', title: 'Slovenia', value: 100227 },
    { code: 'SB', title: 'Solomon Islands', value: 100228 },
    { code: 'SO', title: 'Somalia', value: 100229 },
    { code: 'ZA', title: 'South Africa', value: 100230 },
    { code: 'GS', title: 'South Georgia and the South Sandwich Islands', value: 100231 },
    { code: 'SS', title: 'South Sudan', value: 100232 },
    { code: 'ES', title: 'Spain', value: 100233 },
    { code: 'LK', title: 'Sri Lanka', value: 100234 },
    { code: 'SD', title: 'Sudan', value: 100235 },
    { code: 'SR', title: 'Suriname', value: 100236 },
    { code: 'SJ', title: 'Svalbard and Jan Mayen', value: 100237 },
    { code: 'SE', title: 'Sweden', value: 100238 },
    { code: 'CH', title: 'Switzerland', value: 100239 },
    { code: 'SY', title: 'Syria', value: 100240 },
    { code: 'TW', title: 'Taiwan', value: 100241 },
    { code: 'TJ', title: 'Tajikistan', value: 100242 },
    { code: 'TH', title: 'Thailand ', value: 100244 },
    { code: 'TZ', title: 'Tanzania', value: 100243 },
    { code: 'TL', title: 'Timor-Leste', value: 100245 },
    { code: 'TG', title: 'Togo', value: 100246 },
    { code: 'TK', title: 'Tokelau', value: 100247 },
    { code: 'TO', title: 'Tonga', value: 100248 },
    { code: 'TT', title: 'Trinidad and Tobago', value: 100249 },
    { code: 'TN', title: 'Tunisia', value: 100250 },
    { code: 'TR', title: 'Turkey', value: 100251 },
    { code: 'TM', title: 'Turkmenistan', value: 100252 },
    { code: 'TC', title: 'Turks and Caicos Islands', value: 100253 },
    { code: 'TV', title: 'Tuvalu', value: 100254 },
    { code: 'UG', title: 'Uganda', value: 100255 },
    { code: 'UA', title: 'Ukraine', value: 100256 },
    { code: 'AE', title: 'United Arab Emirates', value: 100257 },
    { code: 'GB', title: 'United Kingdom', value: 100258 },
    { code: 'US', title: 'USA' },
    { code: 'UM', title: 'United States Minor Outlying Islands', value: 100260 },
    { code: 'UY', title: 'Uruguay', value: 100261 },
    { code: 'UZ', title: 'Uzbekistan', value: 100262 },
    { code: 'VU', title: 'Vanuatu', value: 100263 },
    { code: 'VA', title: 'Vatican City', value: 100264 },
    { code: 'VE', title: 'Venezuela', value: 100265 },
    { code: 'VN', title: 'Vietnam', value: 100266 },
    { code: 'VI', title: 'U.S. Virgin Islands', value: 100268 },
    { code: 'WF', title: 'Wallis and Futuna', value: 100269 },
    { code: 'EH', title: 'Western Sahara', value: 100270 },
    { code: 'YE', title: 'Yemen', value: 100271 },
    { code: 'ZM', title: 'Zambia', value: 100272 },
    { code: 'ZW', title: 'Zimbabwe', value: 100273 },
    { code: 'AX', title: 'Åland Islands', value: 100274 }
];
