import React, { useState, useEffect, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { Select, FormControl, Hidden } from '@mui/material';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import AnchorIcon from '@mui/icons-material/Anchor';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PermPhoneMsgTwoToneIcon from '@mui/icons-material/PermPhoneMsgTwoTone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import config from './config.js';
import Modal from '@mui/material/Modal';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SearchOffTwoToneIcon from '@mui/icons-material/SearchOffTwoTone';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import './i18n.js';
import i18n from 'i18next';
import SailingIcon from '@mui/icons-material/Sailing';
import PublicIcon from '@mui/icons-material/Public';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Divider, ListItemAvatar } from '@mui/material';
import axios from "axios";
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import { Delete as DeleteIcon } from '@mui/icons-material';
import GoogleIcon from '@mui/icons-material/Google';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  minWidth: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowX: 'visible',
  maxHeight: '80vh',
  overflowY: 'auto',
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function Navbar(props) {
  const { t } = useTranslation();
  const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language.split('-')[0]; // Return the language part only
  };
  const [locale, setLocale] = useState(() => {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) return savedLocale;

    const browserLang = getBrowserLanguage();
    const availableLocales = ['en', 'sk', 'cs', 'de'];
    return availableLocales.includes(browserLang) ? browserLang : 'en';
  });




  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const pages = [t('Home'), t('Business conditions'), t('GDPR'), t('About us')];
  const [open, setOpen] = React.useState(getCookie('alertLogin'));
  const [openLogout, setOpenLogout] = React.useState(getCookie('alertLogout'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [favoritesList, setFavoritesList] = useState([]);
  const [searchBarVisible, setSearchBarVisible] = useState(false);

  const handleFavoritesClick = (event) => {
    setAnchorEl(event.currentTarget);
    fetchFavoritesList();
  };

  const handleFavoritesClose = () => {
    setAnchorEl(null);
  };

  const fetchFavoritesList = () => {
    const emailFromCookie = getCookie('email');

    const requestData = {
      email: emailFromCookie
    };

    axios.post('/favoritesList', requestData)
      .then(response => {
        setFavoritesList(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error("Error fetching favorites:", error);
      });
  };

  const removeFavorite = (boatId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this favorite?");
    if (isConfirmed) {
      axios.post('/favoritesDelete', { boat_id: boatId, email: props.email })
        .then(() => {
          fetchFavoritesList();
        })
        .catch(error => {
          console.error("Error removing favorite:", error);
        });
    } else {
      console.log("Favorite deletion cancelled by user.");
    }
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ marginLeft: 3 }}>
        <h2>PapaSailor.com</h2>
      </Box>
      <List>
        <ListItem>
          <ListItemButton onClick={handleHome}>{t("Home")}</ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={handleOpenBusiness}>{t("Business conditions")}</ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={handleOpenGDPR}>{t("GDPR")}</ListItemButton>
        </ListItem>
        <ListItem>

          <ListItemButton onClick={handleOpenWall}>{t("Community")}</ListItemButton>

        </ListItem>
        <Divider />
        <ListItem>
          <Box sx={{ flexGrow: 0, marginTop: '18px', marginLeft: '18px' }}>
            <FormControl variant="standard" style={{ minWidth: 150 }}>
              <Select
                value={locale}
                onChange={handleLocaleChange}
                disableUnderline
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={() => null}
              >
                <MenuItem value="en">
                  <Box component="img" src="https://papasailor.com/w20/gb.png" sx={{ mr: 1 }} />
                  <b>English</b>
                </MenuItem>
                <MenuItem value="sk">
                  <Box component="img" src="https://papasailor.com/w20/sk.png" sx={{ mr: 1 }} />
                  <b> Slovenčina</b>
                </MenuItem>
                <MenuItem value="cs">
                  <Box component="img" src="https://papasailor.com/w20/cz.png" sx={{ mr: 1 }} />
                  <b>Čeština</b>
                </MenuItem>
                <MenuItem value="de">
                  <Box component="img" src="https://papasailor.com/w20/de.png" sx={{ mr: 1 }} />
                  <b> Deutsch</b>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </ListItem>
        <Divider sx={{ marginBottom: 2 }}></Divider>
        <ListItem>
          <MailOutlineIcon sx={{ color: 'rgb(2, 136, 209);' }} /> <span class="phone"> info@papasailor.com </span>
        </ListItem>
        <ListItem>
          <PermPhoneMsgTwoToneIcon sx={{ color: 'rgb(2, 136, 209);' }} /> <span class="phone"> +421904919173 </span>
        </ListItem>
        <Divider sx={{ marginBottom: 2, marginTop: 2 }}></Divider>
        <ListItem>
          <Link href="https://www.facebook.com/profile.php?id=61555783273946" target="_blank">
            <Facebook /> <Box sx={{ marginTop: -3, marginLeft: 4 }}> Facebook </Box>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://www.instagram.com/papasailorcom/" target="_blank">
            <Instagram /> <Box sx={{ marginTop: -3, marginLeft: 4 }}> Instagram </Box>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://discord.gg/9ZQNMvtUhV" target="_blank" sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/discord.svg`}
              width="21"
              sx={{
                marginTop: "0px !important",
                filter: "invert(39%) sepia(88%) saturate(4441%) hue-rotate(179deg) brightness(92%) contrast(95%)",
              }}
            />
            <Box sx={{ marginTop: 0, marginLeft: 1 }}>Discord</Box>
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const [openBusiness, setOpenBusiness] = React.useState(false);
  const handleOpenBusiness = () => setOpenBusiness(true);
  const handleCloseBusiness = () => setOpenBusiness(false);

  const [openGDPR, setOpenGDPR] = React.useState(false);
  const handleOpenGDPR = () => setOpenGDPR(true);
  const handleCloseGDPR = () => setOpenGDPR(false);

  const handleLocaleChange = (e) => {
    const selectedLocale = e.target.value;
    setLocale(selectedLocale);
    localStorage.setItem('locale', selectedLocale);
    i18n.changeLanguage(selectedLocale);

    setOpenModal(true);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleHome = () => {
    window.location.href = '/';
  };

  const handleOpenWall = () => {
    window.location.href = '/Thewall';
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const cleanedString = props.userNameCookie.replace(/"/g, '').split(" ")[0];

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    const initialLocale = localStorage.getItem('locale');
    if (!initialLocale) {
      const browserLang = getBrowserLanguage();
      const availableLocales = ['en', 'sk', 'cs', 'de'];
      const defaultLocale = availableLocales.includes(browserLang) ? browserLang : 'en';
      setLocale(defaultLocale);
      localStorage.setItem('locale', defaultLocale);
      i18n.changeLanguage(defaultLocale);
    }
  }, []);


  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setOpenModal(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);

  const debouncedSearch = useCallback(
    _.debounce(async (query) => {
      const lowerCaseQuery = query.toLowerCase();
      if (lowerCaseQuery.length > 2) {
        try {
          const response = await axios.post('/search', { query: lowerCaseQuery });
          setResults(response.data);
        } catch (error) {
          console.error('Error searching:', error);
        }
      } else {
        setResults([]);
      }
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const getCountryCode = (countryName) => {
    const country = countries.find(c => c.name === countryName);
    return country ? country.code : "eu";
  };

  const handleClearSearch = (e) => {
    setSearchQuery("");
    debouncedSearch("a");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleSearchBar = () => {
    setSearchBarVisible(!searchBarVisible);
  };
  const [userId, setUserId] = useState(null);
  const getUserId = async (email) => {
    try {
      const response = await axios.post('/getUserId', { email });
      if (response.data && response.data.user_id) {
        return response.data.user_id;
      } else {
        console.error("User ID not found for email:", email);
        return null;
      }
    } catch (error) {
      console.error("Error fetching user ID:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserId = async () => {
      const fetchedUserId = await getUserId(props.email);
      setUserId(fetchedUserId);
    };

    fetchUserId();
  }, [props.email]);


  return (
    <AppBar variant="None" position="static" sx={{ color: '#ed6c02', backgroundColor: '#001e44' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }}>
            <img
              srcSet={`${process.env.PUBLIC_URL}/logov2-chars.png`}
              width={250}
              style={{ marginBottom: '-6px', zIndex: '999', position: 'relative' }}
              onClick={handleHome}
            />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }}>
            <img
              srcSet={`${process.env.PUBLIC_URL}/logov2-chars.png`}
              width={150}
              style={{ marginBottom: '-6px', zIndex: '999', position: 'relative' }}
              onClick={handleHome}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img
              srcSet={`${process.env.PUBLIC_URL}/papalogo.png`}
              width={250}
              style={{ marginBottom: '-8px', zIndex: '999', position: 'relative' }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <MenuItem onClick={() => setAnchorElNav(null)}>
              <Button key='' onClick={handleHome} sx={{ my: 2, color: 'white', display: 'block' }}> {t("Home")}</Button>
            </MenuItem>
            <MenuItem onClick={() => setAnchorElNav(null)}>
              <Button key='' onClick={handleOpenBusiness} sx={{ my: 2, color: 'white', display: 'block' }}> {t("Business conditions")}</Button>
            </MenuItem>
            <MenuItem onClick={() => setAnchorElNav(null)}>
              <Button key='' onClick={handleOpenGDPR} sx={{ my: 2, color: 'white', display: 'block' }}> {t("GDPR")}</Button>
            </MenuItem>
            <MenuItem onClick={() => setAnchorElNav(null)}>
              <Button key='' onClick={handleOpenWall} sx={{ my: 2, color: 'white', display: 'block' }}> {t("Community")}</Button>
            </MenuItem>
          </Box>

          <Box sx={{ flexGrow: 0, marginLeft: -3, marginRight: '40px', marginTop: '18px', display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}>
            <FormControl variant="standard" style={{ minWidth: 150 }}>
              <Select
                value={locale}
                onChange={handleLocaleChange}
                style={{ color: 'white' }}
                disableUnderline
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={() => null}
              >
                <MenuItem value="en">
                  <Box component="img" src="https://papasailor.com/w20/gb.png" sx={{ mr: 1 }} />
                  <b>English</b>
                </MenuItem>
                <MenuItem value="sk">
                  <Box component="img" src="https://papasailor.com/w20/sk.png" sx={{ mr: 1 }} />
                  <b> Slovenčina</b>
                </MenuItem>
                <MenuItem value="cs">
                  <Box component="img" src="https://papasailor.com/w20/cz.png" sx={{ mr: 1 }} />
                  <b>Čeština</b>
                </MenuItem>
                <MenuItem value="de">
                  <Box component="img" src="https://papasailor.com/w20/de.png" sx={{ mr: 1 }} />
                  <b> Deutsch</b>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{
            color: '#000 !important',
            height: 30,
            padding: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' } }}>
              <Menu
                id="favorites-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleFavoritesClose}
                MenuListProps={{
                  'aria-labelledby': 'favorites-button',
                }}
              >
                {favoritesList.map((favorite) => (
                  <MenuItem key={favorite.boat_id} onClick={handleFavoritesClose}>
                    <ListItemIcon>
                      <Avatar src={favorite.picture} variant="square" sx={{ marginRight: 1 }} />
                    </ListItemIcon>
                    <ListItemText onClick={() => window.open(`/detailsNoDate/${favorite.boat_id}`, '_blank')}>
                      {favorite.name}
                      <Typography variant="body2" color="textSecondary"><img src={favorite.flag} />{favorite.location}</Typography>
                    </ListItemText>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeFavorite(favorite.boat_id)}>
                      <CloseIcon />
                    </IconButton>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, marginTop: -0.5 }}>
              <IconButton aria-label="show search" onClick={toggleSearchBar}>
                <SearchIcon sx={{ color: "#fff" }} />
              </IconButton>
              <IconButton aria-label="show favorites" onClick={handleFavoritesClick}>
                <FavoriteBorderOutlinedIcon sx={{ color: "#fff" }} />
              </IconButton>
              <Tooltip title="profile menu">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={cleanedString} sx={{ width: "25px ", height: "25px", marginRight: 1, marginLeft: 2 }} src={props.userPictureCookie} />
                </IconButton>
              </Tooltip>
              <Typography onClick={handleOpenUserMenu} sx={{ p: 0, marginTop: 0.5, fontSize: '1rem !important', color: "#fff" }}><Hidden smDown>{t("My account")}</Hidden></Typography>
              <Hidden mdUp>
                <div>
                  {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button color="info" onClick={toggleDrawer(anchor, true)}><MenuIcon sx={{ marginRight: 0, fontSize: 35, marginTop: -0.75 }} /></Button>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </Hidden>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: '25px' }}
              id="menu-appbar"
              anchorEl={cleanedString}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {!props.email && (
                <Button onClick={() => {
                  const currentUrl = encodeURIComponent(window.location.href);
                  window.location.href = `${config.BASE_URL}/login?redirect=${currentUrl}`;
                }}><GoogleIcon sx={{ marginRight: 1 }} /> {t("Login")}</Button>
              )}
              {props.email && (

                <MenuItem>

                  <Button onClick={() => window.location.href = `${config.BASE_URL}/profile/${userId}`}> <AccountBoxTwoToneIcon sx={{ marginRight: 1 }} /> {t("Profile")}</Button>
                </MenuItem>

              )}
              {props.email && (

                <MenuItem>

                  <Button onClick={() => window.location.href = `${config.BASE_URL}/resume`}> <PictureAsPdfTwoToneIcon sx={{ marginRight: 1 }} /> {t("Resume")}</Button>
                </MenuItem>
                

              )}

              {props.email && (
                <MenuItem>
                  <Button onClick={() => window.location.href = `${config.BASE_URL}/Favoritesweb`}><FavoriteTwoToneIcon sx={{ marginRight: 1 }} />{t("Favorites")}</Button>
                </MenuItem>

              )}

              {props.email && (
                <MenuItem>
                  <Button onClick={() => window.location.href = `${config.BASE_URL}/Thewall`}><PeopleTwoToneIcon sx={{ marginRight: 1 }} />{t("Community")}</Button>
                </MenuItem>

              )}

              {props.email && (
                <MenuItem>
                  <Button onClick={() => window.location.href = `${config.BASE_URL}/logout`}><LogoutIcon sx={{ marginRight: 1 }} />{t("Logout")}</Button>
                </MenuItem>

              )}





            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {searchBarVisible && (
        <Box sx={{ flexGrow: 1, marginBottom: -2 }}>
          <AppBar position="static" sx={{ flexGrow: 1, height: 155, backgroundColor: "#001e44", marginBottom: 0 }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', height: 40, marginTop: 5 }}>
                <Search sx={{ flexGrow: 0.3 }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Bavaria 46 Sibenik..."
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Search>
              </Box>
            </Toolbar>
          </AppBar>
          <Box position="static" sx={{ flexGrow: 1, backgroundColor: "#FFF" }} display="flex" justifyContent="center" alignItems="center">
            <MenuList sx={{ maxWidth: 400, paddingTop: 0, paddingBottom: 0 }}>
              {results.length > 0 && (
                <MenuItem>
                  <IconButton onClick={handleClearSearch} >
                    <SearchOffTwoToneIcon /> <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>clear search</Typography>
                  </IconButton>
                </MenuItem>
              )}
              {results.map((result, index) => {
                const countryCode = getCountryCode(result.country_name);
                return (
                  <React.Fragment key={result.boat_id}>
                    {index > 0 && <Divider />}
                    <MenuItem>
                      <Link
                        href={process.env.PUBLIC_URL + "/detailsNoDate/" + result.boat_id}
                        target="_blank"
                        style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={result.picture}
                            alt={result.country_name}
                            sx={{ width: 56, height: 56, marginRight: 2 }}
                            variant="rounded"
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography variant="body1" color="textPrimary" sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start" }}>
                            {(result.name + " " + result.boat_name).trim()}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={"https://papasailor.com/w20/" + (countryCode.toLowerCase()) + ".png"} alt={result.country_name} style={{ marginRight: 4 }} />
                            {result.location_name}
                          </Typography>
                        </ListItemText>
                      </Link>
                    </MenuItem>
                  </React.Fragment>
                );
              })}
              {results.length > 0 && (
                <MenuItem >
                  <IconButton onClick={handleClearSearch} display="flex" justifyContent="center" alignItems="center">
                    <SearchOffTwoToneIcon /> <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>clear search</Typography>
                  </IconButton>
                </MenuItem>
              )}
            </MenuList>
          </Box>
        </Box>
      )}
      <div>
        <Modal
          open={openBusiness}
          onClose={handleCloseBusiness}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Všeobecné obchodné podmienky portálu papasailor.com")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>


              <p><h3>Preambula</h3></p>

              <p>Tieto Všeobecné obchodné podmienky (ďalej len „VOP“) upravujú vzťahy medzi spoločnosťou PapaSailor s.r.o. (ďalej len „Sprostredkovateľ“), a jej klientmi v súvislosti so Sprostredkovávaním prenájmu lodí a predaju služieb spojených s prenájmom lodí. Sprostredkovávanie lodí a predaju služieb sa deje najmä, avšak nie výlučne, prostredníctvom internetového portálu papasailor.com. Poskytovateľom lode, alebo služieb je tretia strana a z pravidla majiteľ lode, charterová spoločnosť,  cestovná agentúra, alebo iný sprostredkovateľ (ďalej len „Poskytovateľ“).</p>

              <h3>1. Povinnosti sprostredkovateľa</h3>

              <p>1.1 Sprostredkovateľ sa zaoberá sprostredkovávaním prenájmu lodí a sprostredkovaním predaju služieb s ním spojených a neúčtuje svojim klientom žiadne poplatky. Jeho odmena je viazaná na províziu od Poskytovateľa.</p>

              <p>1.2 Zodpovednosť Sprostredkovateľa sa obmedzuje na úlohu Sprostredkovania a nezahŕňa zodpovednosť za škody vzniknuté klientovi, ktoré boli spôsobené zo strany Poskytovateľa vo vzťahu vyplývajúceho zo vzájomnej zmluvy medzi klientom a Poskytovateľom, treťou stranou, alebo spôsobené vyššou mocou.</p>

              <h3>2. Práva a povinnosti záujemcu (klienta), Právo na reklamáciu</h3>

              <p>2.1 Klient prehlasuje, že má viac ako 18 rokov a je právoplatne spôsobilý uzatvárať právne vzťahy.</p>

              <p>2.2 Klient súhlasí s komunikáciou a korešpondenciou (email, SMS, telefonický kontakt atď.) zo strany Sprostredkovateľa, alebo Poskytovateľa, ktorú je povinný strpieť, ale len v rozsahu ich vzťahu k sprostredkovávanému prenájmu lodí alebo predaju služieb.</p>

              <p>2.3 Klient berie na vedomie, že zmluvný vzťah je uzatvorený výlučne medzi klientom a Poskytovateľom lode, nie so Sprostredkovateľom, ktorý pôsobí len ako prostredník. To sa netýka záväzkov a dojednaní medzi Klientom a Sprostredkovateľom.</p>

              <p>2.4 Klient berie na vedomie, že práva a povinnosti klienta pri prenájme lode vyplývajúce zo zákona sa riadia právnou úpravou domovskej krajiny Poskytovateľa a miesta poskytnutia služby (napr. Chorvátsko, Taliansko, Grécko atď.).</p>

              <p>2.5 Klient je povinný sa riadiť podmienkami Poskytovateľa, alebo zmluvným vzťahom s Poskytovateľom. Podmienky jednotlivých Poskytovateľov budú zverejnené na webovom sídle Sprostredkovateľa, alebo webe sídla samotného Poskytovateľa. Klient je povinný sa s nimi oboznámiť pred uzavretím zmluvy.</p>

              <p>2.6 Klient má právo na Poskytovanie služieb od Sprostredkovateľa riadne a včas v rozsahu: Sprostredkovateľ zabezpečí uzavretie zmluvy s Poskytovateľom v termínoch a v rozsahu podľa požiadaviek klienta. Klient má právo na bezodplatné odstránenie vád týkajúcich sa poskytnutých služieb Sprostredkovateľom.</p>

              <p>2.7 Klient má právo na reklamáciu.</p>

              <p>2.8 Reklamáciu týkajúcu sa prenájmu lode je klient povinný uplatniť u Poskytovateľa najneskôr pri vrátení lode Poskytovateľovi a to spísaním reklamačného protokolu, ktorý je k dispozícií u Poskytovateľa, ale uvedením reklamácie do preberacieho/odovzdávacieho protokolu Poskytovateľa.</p>

              <p>2.9 Klient môže reklamovať u Poskytovateľa najmä, ale nie výhradne:</p>

              <p>- neposkytnutie predmetu nájmu v dohodnutej dobe a rozsahu</p>
              <p>- nefunkčnosť predmetu nájmu (lode), alebo jeho častí (nefunkčnosť motora, elektronických/manuálnych vrátkov, osvetlenia alebo iných častí lode)</p>
              <p>- chýbajúce vybavenie lode (lanovie, fendre, záchranné vesty, navigačné pomôcky atď.)</p>
              <p>- poškodené časti lode (poškodenie plachiet, lanovia atď. )</p>

              <p>2.10 Reklamáciu týkajúcu sa sprostredkovania môže klient uplatniť kedykoľvek až do momentu úspešného uzavretia zmluvy medzi klientom a Poskytovateľom a to písomne na emailovú adresu info@papasailor.com, alebo na adresu sídla spoločnosti PapaSailor s.r.o., Pezinská 13, 949 01 Nitra, Slovenská republika.</p>

              <p>2.11 Klient môže reklamovať u Sprostredkovateľa najmä, ale nie výhradne:</p>

              <p>- nedodanie služby (uzavretia zmluvy medzi Poskytovateľom a Klientom)</p>

              <p>2.12 Lehoty pre vybavenie reklamácie sa riadia legislatívou príslušnej krajiny Poskytovateľa.</p>

              <p>2.13 V prípade nedostatkov týkajúcich sa nefunkčných, alebo chýbajúcich častí predmetu nájmu je Poskytovateľ povinný vyriešiť reklamáciu bezodkladne a to nahradením nefunkčných, alebo chýbajúcich častí, ich opravou, alebo čiastočnou refundáciou na účet Klienta.</p>

              <p>2.14 V prípade neposkytnutia dohodnutého predmetu prenájmu má klient právo na sto percentnú refundáciu uhradenej sumy za predmet prenájmu.</p>

              <h3>3. Zmluvný vzťah</h3>

              <p>3.1 Účastníkmi zmluvného vzťahu sú klient (fyzická, alebo právnická osoba) a Poskytovateľ.</p>

              <p>3.2 Sprostredkovateľ do zmluvného, alebo obdobného vzťahu s klientom vstupuje v rozsahu poskytovania služieb spojených so sprostredkovaním uzavretia zmluvy medzi klientom a Poskytovateľom.</p>

              <p>3.3 Zmluvný vzťah medzi klientom a Poskytovateľom sa riadi zmluvou medzi nimi samotnými a obchodnými podmienkami Poskytovateľa.</p>

              <p>3.4 Zmluvný vzťah medzi klientom a Poskytovateľom vzniká prijatím čiastočnej alebo úplnej úhrady za rezerváciu lode a následným potvrdením prenájmu lode zo strany Poskytovateľa.</p>

              <h3>4. Platba a ceny</h3>

              <p>4.1 Platba za prenájom lode je povinná vopred a v súlade s podmienkami Poskytovateľa. O podmienkach platby je klient riadne informovaný pred uzavretím rezervácia v časti rezervačného formulára na portály papasailor.com. Platba môže byť vykonaná čiastočne alebo celkovo prostredníctvom platobnej karty online alebo bankovým prevodom.</p>

              <h3>5. Zrušenie rezervácie / odstúpenie od zmluvy</h3>

              <p>5.1 Vo všeobecnosti sa uplatňuje 100% storno poplatok v prípade výpovede zo zmluvy zo strany klienta. Jednotlivý Poskytovatelia môžu poskytnúť možnosť storna s vrátením celej sumy uhradenej klientom, alebo vrátením časti sumy uhradenej klientom. V týchto prípadoch bude klient o podmienkach možnosti zrušenia rezervácie informovaný vždy pred uzavretím zmluvy prostredníctvom rezervačného formuláru. Podľa Poskytovateľa môže byť rezervácia s nulovým, čiastočným, alebo stopercentným storno poplatkom do určitého termínu, alebo odo dňa uzavretia zmluvy.  </p>

              <p>5.2 Zrušenie rezervácie a vrátenia platby bez storno poplatku je možné bez ohľadu na podmienky Poskytovateľa do momentu sprostredkovania uzavretia zmluvy medzi klientom a Poskytovateľom. Po uzavretí zmluvy sa podmienky zrušenia rezervácie riadia výlučne podmienkami uvedenými v zmluve, alebo príslušnými obchodnými podmienkami Poskytovateľa. </p>

              <p>5.3 V prípade, že k sprostredkovaniu zmluvy medzi Poskytovateľom a klientom nedôjde, Sprostredkovateľ vráti uhradené finančné prostriedky klientovi v plnej výške bez zbytočného oneskorenia.</p>

              <h3>6. Vyhlásenie o odmietnutí zodpovednosti</h3>

              <p>6.1 Sprostredkovateľ nezodpovedá za škody, ktoré nevznikli priamym pôsobením spoločnosti PapaSailor s.r.o., sú dôsledkom konania klienta, Poskytovateľa, tretích strán, alebo dôsledkom vyššej moci.</p>

              <h3>7. Ostatné dojednania</h3>

              <p>7.1 Informácie o lodiach zobrazené na portáli sú Poskytované tretími stranami. Portál je internetovým inzertným miestom Poskytovateľov lodí (majiteľov lodi, cestovných agentúr, alebo iných sprostredkovateľov). Informácie zverejnené na portály nie sú záväzné a môžu sa v čase meniť. Sprostredkovateľ nezodpovedá za neúplné alebo nepravdivé informácie poskytované prostredníctvom portálu.</p>


              <p>Tieto VOP vstupujú do platnosti a účinnosti dňom ich zverejnenia na webovom sídle spoločnosti PapaSailor s.r.o..</p>

            </Typography>
            <Button key='' onClick={handleCloseBusiness} sx={{ my: 2, color: '#000', display: 'block' }}>{t("Close")}</Button>
          </Box>
        </Modal>
        <Modal
          open={openGDPR}
          onClose={handleCloseGDPR}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Ochrana a spracovanie osobných údajov, práva dotknutej osoby
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Zásady ochrany osobných údajov na portáli papasailor.com


              <h3>Súhlas so spracovaním osobných údajov</h3>

              <p>Súhlasom so spracovaní osobných údajov povoľuje dotknutá osoba spoločnosti PapaSailor s.r.o. , so sídlom Pezinská 4379/13, 94901 Nitra, IČO: 55 917 593, zapísanej vo verejnom registri vedenom na Obchodnom registri Okresného súdu Nitra, oddiel Sro, vložka 62345/N (Ďalej len "Správca"), aby v zmysle zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej len "zákon o ochrane osobných údajov") spracovávala tieto osobné údaje dotknutej osoby:</p>

              <p>meno a priezvisko, názov spoločnosti, adresa, e-mail, telefónne číslo</p>

              <p>Meno, priezvisko, názov spoločnosti, adresu, telefónne číslo a e-mail je nutné spracovať za účelom fakturácie a sprostredkovania uzatvárania zmlúv . Tieto údaje budú Správcom spracované po dobu 10 rokov.</p>

              <h3>Spracovanie osobných údajov</h3>

              <p>Súhlasom so spracovaní osobných údajov udeľuje dotknutá osoba svoj výslovný súhlas s vyššie uvedeným spracovaním. Súhlas možno vziať kedykoľvek späť, zaslaním emailu na adresu info@papasailor.com alebo zaslaním listu na adresu sídla Správcu.</p>

              <p>Spracovanie osobných údajov je vykonávané Správcom. Osobné údaje môžu spracovávať aj títo spracovatelia:</p>

              <p>Poskytovatelia služieb o ktoré dotknutá osoba výslovne požiada prostredníctvom rezervačného formulára na webe papasailor.com. </p>

              <p>Poskytovateľ služieb spracováva osobné údaje za účelom fakturácie, vytvárania zmlúv s dotknutou osobout a evidencie fakturácie a uzavretých zmlúv.</p>

              <h3>Práva na opravu, zabudnutie a prístup k osobným údajom dotknutej osoby</h3>

              <p>Podľa zákona o ochrane osobných údajov má dotknutá osoba právo:</p>

              <p>vziať súhlas kedykoľvek späť</p>
              <p>vyžiadať od nás informáciu, aké vaše osobné údaje spracovávame</p>
              <p>vyžiadať od nás vysvetlenie ohľadne spracovania osobných údajov</p>
              <p>vyžiadať si u nás výpis týchto údajov a tieto nechať aktualizovať alebo opraviť</p>
              <p>požadovať od nás vymazanie týchto osobných údajov</p>



              <h3>V prípade pochybností o dodržiavaní povinností súvisiacich so spracovaním osobných údajov obrátiť sa na nás alebo na Úrad na ochranu osobných údajov.</h3>

              <p>Ak máte akékoľvek otázky týkajúce sa týchto Zásad ochrany osobných údajov, chcete uplatniť svoje práva alebo máte podnet na zlepšenie ochrany vašich osobných údajov, môžete nás kontaktovať na adrese:</p>

              <p>PapaSailor s.r.o.</p>
              <p>Pezinská 4379/13</p>
              <p>Nitra 949 01</p>

              <p>Zmeny Zásad ochrany osobných údajov</p>

              <p>Tieto Zásady ochrany osobných údajov môžeme z času na čas aktualizovať. O každej zmene vás budeme informovať na našich webových stránkach.</p>
            </Typography>
            <Button key='' onClick={handleCloseGDPR} sx={{ my: 2, color: '#000', display: 'block' }}>{t("Close")}</Button>
          </Box>
        </Modal>
      </div>
    </AppBar>
  );
}

export default Navbar;

const countries = [
  { code: 'HR', name: 'Croatia', value: 1, type: 'country' },
  { code: 'GR', name: 'Greece', value: 100116, type: 'country' },
  { code: 'TR', name: 'Turkey', value: 100181, type: 'country' },
  { code: 'IT', name: 'Italy', value: 100147, type: 'country' },
  { code: 'ES', name: 'Spain', value: 100119, type: 'country' },
  { code: 'NO', name: 'Norway', value: 100156, type: 'country' },
  { code: 'VG', name: 'British Virgin Islands', value: 126446, type: 'country' },
  { code: 'FR', name: 'France', value: 100118, type: 'country' },
  { code: 'SC', name: 'Seychelles', value: 124874, type: 'country' },
  { code: 'TH', name: 'Thailand', value: 100174, type: 'country' },
  { code: 'SI', name: 'Slovenia', value: 100144, type: 'country' },
  { code: 'VI', name: 'U.S. Virgin Islands', value: 1222032, type: 'country' },
  { code: 'BS', name: 'Bahamas', value: 491658, type: 'country' },
  { code: 'MT', name: 'Malta', value: 100126, type: 'country' },
  { code: 'ME', name: 'Montenegro', value: 100143, type: 'country' },
  { code: 'SE', name: 'Sweden', value: 100155, type: 'country' },
  { code: 'PF', name: 'French Polynesia', value: 842497, type: 'country' },
  { code: 'BQ', name: 'Caribbean', value: 485571, type: 'country' },
];
